<template>
  <section
    v-if="uspBarItems.length"
    class="mb-0 bg-legacy-grey-default overflow-hidden flex font-gilroy h-[30px] sm:h-[40px]"
    data-testid="usp-bar"
  >
    <BaseCarousel
      class="w-full content-container"
      :settings="carouselSetting"
      :auto-play="{ itemCount: uspBarItems.length, maxWidth: 1024 }"
      @slideChange="onSlideChange"
    >
      <template #slides>
        <div
          v-for="(item, i) in uspBarItems"
          :ref="'uspBannerElement_' + i"
          :key="item.name"
          class="my-0 h-[30px] sm:border-l ml-[2px] border-grey-5 first-of-type:border-transparent"
          data-testid="usp-banner-element"
        >
          <div
            class="flex flex-col h-full justify-center items-center text-size-2 bg-legacy-grey-default overflow-hidden p-0 sm:px-2.5 leading-4"
          >
            <div
              v-if="item.type.selectionValues[0].label.trim() === 'trustpilot'"
              ref="trustbox"
              class="trustpilot-widget scale-[0.7]"
              data-locale="en-GB"
              data-template-id="5419b732fbfb950b10de65e5"
              data-businessunit-id="47e3cf0e0000640005022d0a"
              data-style-height="20px"
              data-theme="light"
            />
            <component
              :is="item.href ? 'nuxt-link' : 'div'"
              v-else
              :to="item.href"
              class="flex gap-2 xl:gap-4 items-center"
            >
              <img
                :src="item.icon"
                :alt="item.title"
                class="w-auto h-[20px] xl:h-[25px] shrink-0"
                width="25"
                height="25"
              />
              <div
                class="flex flex-row sm:flex-col items-center sm:items-start gap-2 sm:gap-[2px]"
              >
                <p
                  class="text-blue font-bold uppercase text-[clamp(12px,1vw,13px)] leading-none"
                >
                  {{ item.title }}
                </p>
                <p class="text-[12px]">{{ item.subTitle }}</p>
              </div>
            </component>
          </div>
        </div>
      </template>
    </BaseCarousel>
  </section>
</template>

<script>
import BaseCarousel from "~/components/UI/carousel/BaseCarousel.vue";
import { BloomreachFolderPaths } from "~/services/api/bloomreach.api";
import { fetchBrDocument } from "~/services/bloomreach.service";

export default {
  name: "UspBanner",
  components: {
    BaseCarousel,
  },
  data() {
    return {
      uspBarItems: [],
      carouselSetting: {
        showDots: false,
        showArrows: false,
        slidesPerPage: 5,
        loop: true,
        paginateBySlide: true,
        gutter: 0,
        responsive: [
          {
            maxWidth: 1050,
            slidesPerPage: 3,
          },
          {
            maxWidth: 767,
            slidesPerPage: 2,
          },
          {
            maxWidth: 480,
            slidesPerPage: 1,
          },
        ],
      },
    };
  },
  async fetch() {
    const response = await fetchBrDocument(
      BloomreachFolderPaths.USPBanner,
      "items",
      this.$config.brxmContentEndpoint
    );

    if (!response) return;
    const { itemOne, itemTwo, itemThree, itemFour, itemFive } = response;
    this.uspBarItems = [itemOne, itemTwo, itemThree, itemFour, itemFive];
  },
  updated() {
    const trustbox = this.$refs.trustbox?.[0];
    if (window.Trustpilot && trustbox) {
      window.Trustpilot.loadFromElement(trustbox);
    }
  },
  methods: {
    onSlideChange(currentSlide) {
      const currentElement =
        this.$refs[`uspBannerElement_${currentSlide.index}`][0];

      const allElements = Object.values(this.$refs);
      allElements.forEach((element) => {
        if (element[0] !== currentElement) {
          element[0].classList.remove(
            "border-transparent",
            "first-of-type:border-transparent"
          );
          element[0].classList.add("border-grey-5");
        } else {
          element[0].classList.remove("border-grey-5");
          element[0].classList.add("border-transparent");
        }
      });
    },
  },
};
</script>
